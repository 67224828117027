import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { NoSymptomsAddedWrapper } from "../SelectSymptoms"
import { getRemediesByAilmentID } from "../../../services/remedy.services"
import { getAilments } from "../../../services/ailment.services"
import { navigate } from "@reach/router"
import {
  PageHeaderTypeThree,
  PageSubHeaderTypeThree,
  BreakpointTablet,
  PageParagraphTypeTwo,
} from "../../Layout/GlobalStyle"
import { Button } from "@material-ui/core"
import { SYMPTOM_CHECKER_SELECT_SYMPTOMS } from "../../Layout/routes"
import withLocation from "../../common/withLocation"
import { WalkThroughContainer, SymptomCheckerStyled } from "../index"
import LoadingContainer from "../../common/LoadingContainer"
import IconButton from "@material-ui/core/IconButton"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import AilmentResult from "./AilmentResult"

export const RemedyItemStyled = styled.div`
  .remedy-instruction-text {
    font-size: small;
    margin-top: 0px;
    color: #554c57;
    font-family: var(--Font2);
  }

  .remedy-instructions {
    padding-bottom: 20px;
  }
  .remedy-detail-title {
    font-size: medium;
    font-weight: bold;
    font-family: var(--Font1);
  }
  .remedy-title {
    font-family: var(--Font1);
    font-weight: bold;
    text-transform: uppercase;
  }

  .herbs-used {
    display: flex;
  }

  .herb-used-item {
    margin-left: 5px;
    font-size: small;
    padding: 0px 5px 0px 5px;
    border: 1px solid black;

    :first-of-type {
      margin-left: 0px;
    }
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 50px;

  .general-remedies {
    font-family: var(--Font2);
    font-weight: bold;
    color: #140d3d;
  }

  .ailment-list-wrapper {
    display: block;
    min-height: 500px;
  }

  .ailment-results-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    height: 70px;
  }

  .conditions-that-match {
    font-size: medium;
    font-weight: lighter;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 70px;
  }
`

const AilmentListItemStyled = styled.div`
  height: 70px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  transition: 0.1s ease-in-out;
  cursor: pointer;

  .ailment-name {
    font-size: medium;
    font-family: var(--Font2);
    font-weight: bold;
    ${props =>
      props.isSelected
        ? `color: white; text-shadow: 1px 1px 1px rgba(0,0,0,0.3);`
        : "color: #596f81;"}
  }

  svg {
    ${props => (props.isSelected ? `fill: white;` : "")}
  }

  .go-button {
  }

  :hover {
    ${props =>
      props.isSelected ? `background: #05aa97;` : "background: #05aa978c;"}
  }

  ${props =>
    props.isSelected ? `background: #05aa97;` : "background: #05aa9769;"}
`

const defaultRemediesLimit = 1

function AilmentResults(props) {
  const scrollToRef = useRef()
  const containerRef = useRef()

  let [ailments, setAilments] = useState([])
  let [selectedAilment, setSelectedAilment] = useState({
    _id: "",
    name: "",
  })

  const [symptoms, setSymptoms] = useState([])

  let [loadingAilments, setLoadingAilments] = useState(false)

  // START OF REMEDIES USE STATE
  const [remedies, setRemedies] = useState([])
  const [remediesCount, setRemediesCount] = useState(0)
  const [remediesLimit, setRemediesLimit] = useState(defaultRemediesLimit)
  const [loadingRemedies, setLoadingRemedies] = useState(false)
  const [fetchingMoreRemedies, setFetchingMoreRemedies] = useState(false)
  // END OF REMEDIES USE STATE

  const handleSelectAilment = item => {
    setSelectedAilment(item)

    const widthOfContainer = containerRef.current.clientWidth
    if (widthOfContainer <= 720) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  // START OF USE EFFECTS
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadRemedies()
    }

    return () => (isSubscribed = false)
  }, [selectedAilment])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadAilments()
    }

    return () => (isSubscribed = false)
  }, [props.search && props.search.symptoms])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed && props && props.search && props.search.symptoms) {
      let parsedSymptoms = props.search.symptoms.split(",")
      if (parsedSymptoms) {
        setSymptoms(parsedSymptoms)
      }
    }

    return () => (isSubscribed = false)
  }, [props.search && props.search.symptoms])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fetchMoreRemedies()
    }
    return () => (isSubscribed = false)
  }, [defaultRemediesLimit !== remediesLimit])

  // END OF USE EFFECTS

  // START OF API CALLS

  const loadRemedies = () => {
    setLoadingRemedies(true)
    getRemediesByAilmentID(selectedAilment._id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          setLoadingRemedies(false)
          setRemedies(res.data.remedies)
        }
      })
      .catch(err => {
        setLoadingRemedies(false)

        throw err
      })
  }

  const fetchMoreRemedies = () => {
    setFetchingMoreRemedies(true)
    getRemediesByAilmentID(selectedAilment._id, `?limit=${remediesLimit}`)
      .then(res => {
        if (res.status === 200) {
          setFetchingMoreRemedies(false)
          setRemedies(res.data.remedies)
          setRemediesCount(res.data.count)
        }
      })
      .catch(err => {
        setFetchingMoreRemedies(false)
        throw err
      })
  }

  const loadAilments = () => {
    setLoadingAilments(true)
    getAilments(`?symptoms=${props.search.symptoms}`)
      .then(res => {
        if (res.status === 200) {
          setLoadingAilments(false)

          setAilments(res.data)
        }
      })
      .catch(err => {
        setLoadingAilments(false)

        throw err
      })
  }

  // END OF API CALLS

  const handleBack = () => {
    navigate(`${SYMPTOM_CHECKER_SELECT_SYMPTOMS}?selected=${symptoms}`)
  }

  // START OF RENDER ITEMS
  let renderAilments = ailments.length
    ? ailments.map((item, index) => {
        return (
          <AilmentListItemStyled
            isSelected={item._id === selectedAilment._id}
            onClick={() => handleSelectAilment(item)}
            key={index}
          >
            <div className="ailment-name">{item.name}</div>
            <IconButton size="large">
              <NavigateNextIcon />
            </IconButton>
          </AilmentListItemStyled>
        )
      })
    : ""

  // END OF RENDER ITEMS

  return (
    <SymptomCheckerStyled>
      <div className="walk-through-wrapper">
        <h1 className="walk-through-title"></h1>
        <PageHeaderTypeThree
          fontFamily={"var(--Font1NotCondensed)"}
          marginBottom={5}
        >
          Results
        </PageHeaderTypeThree>
        <PageSubHeaderTypeThree marginTop={0}>
          Ailments that match your symptoms
        </PageSubHeaderTypeThree>
        <WalkThroughContainer ref={containerRef}>
          {loadingAilments ? (
            <LoadingContainer />
          ) : ailments ? (
            <Container>
              <div className="ailment-list-wrapper">
                <div className="ailment-results-list">{renderAilments}</div>
              </div>

              <AilmentResult
                remediesLimit={remediesLimit}
                remediesCount={remediesCount}
                setRemediesLimit={setRemediesLimit}
                fetchingMoreRemedies={fetchingMoreRemedies}
                loadingRemedies={loadingRemedies}
                remedies={remedies}
                ref={scrollToRef}
                ailment={selectedAilment}
              />
            </Container>
          ) : !ailments ? (
            <NoSymptomsAddedWrapper>
              <div className="message">
                Didn't find any ailments with your symptoms
              </div>
            </NoSymptomsAddedWrapper>
          ) : (
            ""
          )}

          <footer className="symptom-checker-footer">
            <Button onClick={() => handleBack()} disableElevation>
              Back
            </Button>

            <div></div>
          </footer>
        </WalkThroughContainer>
      </div>
      <div className={"symptom-checker-message-columns"}>
        <PageParagraphTypeTwo>
          This tool does not provide medical advice It is intended for
          informational purposes only. It is not a substitute for professional
          medical advice, diagnosis or treatment. Never ignore professional
          medical advice in seeking treatment because of something you have read
          on the FindAnHerb Site. If you think you may have a medical emergency,
          immediately call your doctor or dial 911.
        </PageParagraphTypeTwo>
      </div>
    </SymptomCheckerStyled>
  )
}

AilmentResults.defaultProps = {
  ailments: [],
}

export default withLocation(AilmentResults)

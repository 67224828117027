import React from "react"
import Layout from "../../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import SelectSymptoms from "../../components/SymptomChecker/SelectSymptoms"
import ProtectedPage from "../../components/common/ProtectedPage"

const SymptomCheckerPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allMongodbHerbsSymptoms {
    edges {
      node {
        id
        name
        mongodb_id
        slug
      }
    }
  }
  }
`)



  return (
    <Layout seoProps={{title: "Symptom Checker"}}>
      <ProtectedPage>
        <SelectSymptoms data={data} />
      </ProtectedPage>

    </Layout>
  )
}



export default SymptomCheckerPage

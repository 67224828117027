import React, { useEffect, useState } from 'react'
import { getSymptoms } from '../../services/symptom.services';
import styled from 'styled-components';
import { getAilments } from '../../services/ailment.services';
import SelectSymptoms from './SelectSymptoms';
import AilmentResults from './AilmentResults';
import { Link } from 'gatsby';
import { SYMPTOM_CHECKER_SELECT_SYMPTOMS } from '../Layout/routes';
import {BreakpointTablet, PageParagraphTypeOne} from '../Layout/GlobalStyle'

export const SymptomCheckerStyled = styled.div`
    padding-top: 20px;
    margin: auto;
    width: 100%;
    max-width: 1000px;
    max-height: 100%;

    .walk-through-title{
        font-size: 31px;
        font-weight: lighter;
        font-family: var(--Font2);
        color: #140d3d;
    }

    .input-column{
        display: flex;
        flex-direction: column;
        position: relative;

        input{
            height: 40px;
            font-size: 16px;
            color: var(--GreyColorOne);
            font-family: var(--Font1NotCondensed);
            padding-left: 15px;
            font-weight: bold;
        }

        input:focus {
            border-color: var(--Color1);
        }

        
    }

    .symptom-checker-message-columns{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .conditions-that-match{
        font-size: 18px;
    }

 
`

export const WalkThroughContainer = styled.div`
    background: white;
    box-shadow:  0 1px 4px 0 rgba(187,187,187,.5);
    width: 100%;
    display: flex;
    max-height: 100%;


    flex-direction: column;
    padding: 25px 25px 0px 25px;

    .wrapper-label{
        font-family: var(--Font1NotCondensed);
        color: var(--TextTitleColor2);
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 19.2px;
        line-height: 29px;
    }

    .input-column-label{
        font-family: var(--Font1NotCondensed);
        color: var(--TextTitleColor2);
        margin-bottom: 5px;
        font-weight: bold;
    }

    .symptom-column-item{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }

    .symptom-checker-columns{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;

        @media only screen and (max-width: ${BreakpointTablet + 'px'}){
            grid-template-columns: 1fr;
            grid-gap: 70px;

        }
    }

    .symptom-checker-footer{
        display: flex;
        justify-content: space-between;
        padding-top: 17px;
        padding-bottom: 16px;
        align-items: center;
        height: 100%;
        height: 80px;

        button{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-family: var(--Font1);
            text-transform: uppercase;
            font-size: large;
        }

        .next-button{
            
        }
        .back-button{
            border-radius: 4px;
            font-family: var(--Font1);
            text-transform: uppercase;
            font-size: large;
        }
    }
   
    
`



function SymptomChecker(props) {




    return (
        <SymptomCheckerStyled>
            <div className="walk-through-wrapper">
                <h1 className="walk-through-title">Symptom Checker</h1>
                <WalkThroughContainer>
                    <p>Paragraph explaining how our symptom checker works</p>
                    <Link to={SYMPTOM_CHECKER_SELECT_SYMPTOMS}>Start</Link>
                </WalkThroughContainer>
                <PageParagraphTypeOne>
                This tool does not provide medical advice It is intended for informational purposes only. It is not a substitute for professional medical advice, diagnosis or treatment. Never ignore professional medical advice in seeking treatment because of something you have read on the FindAnHerb Site. If you think you may have a medical emergency, immediately call your doctor or dial 911.
                </PageParagraphTypeOne>
            </div>

        </SymptomCheckerStyled>

    )
}


export default SymptomChecker
import React, { useContext, useState, useEffect } from 'react'
import { LOGIN } from '../Layout/routes';
import AuthUserContext from '../../providers/AuthProvider';
import jwt_decode from 'jwt-decode'
import { getJWT } from '../Layout'
import LoadingContainer from './LoadingContainer'
import { navigate } from "@reach/router"
function ProtectedPage(props) {
    let [isReady, setIsReady] = useState(false)
    let { user } = useContext(AuthUserContext)

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handleAuth()
        }

        return () => isSubscribed = false;
    }, [user && user.id])




    const handleAuth = async () => {
        let token = await getJWT()
        let windowPath = typeof window !== "undefined" ? window.location.pathname : false

        if (token) {

            const decoded = jwt_decode(token)
            // console.log(decoded)
            if (decoded && decoded.id) {
                setIsReady(true)
            }
        } else {
            navigate(`${LOGIN}${windowPath ? `?redirect=${windowPath}` : ''}`)
            // let {asPath} = router;
            // Router.push(`${LOGIN}?redirect=${asPath}`)
        }
    }






    return (
        <> {isReady ? props.children : <LoadingContainer />} </>
    )
}



export default ProtectedPage
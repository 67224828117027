import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getSymptoms } from '../../services/symptom.services';
import { getAilments } from '../../services/ailment.services';
import { WalkThroughContainer, SymptomCheckerStyled } from './index'
import { navigate } from "@reach/router"
import { SYMPTOM_CHECKER_AILMENT_RESULTS } from '../Layout/routes';
import {PageHeaderTypeThree, PageSubHeaderTypeThree, PageParagraphTypeOne, PageParagraphTypeTwo} from '../Layout/GlobalStyle'
import withLocation from '../common/withLocation';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    iconButton: {
      fontSize: 10,
      padding: 0,
      marginLeft: 4
    },
    clearIcon: {
        fontSize: 15,
        lineHeight: 15,
    
    }
  }));

const SymptomStyledItem = styled.button`
    margin: 3px;
    min-height: 30px;
    padding: 10px 11px;
    text-align: center;
    font-size: 13px;
    border: 0px solid transparent;
    background: #05aa9769;
    border-radius: 4px;
    box-shadow: 1px 1px 0 0 rgba(0,0,0,.14);
    font-family: var(--Font2);
    
    transition: 0.2s ease-in-out;
    cursor: pointer;

    .symptom-item-text{
        display: flex;
        line-height: 13px;
        align-items: center;
        color: #596f81;
    }




    :hover{
        background: var(--LightGreenDarker);

        .symptom-item-text{
            color: white;
            svg{
                fill: white;
            }

        }
        
    }
    :disabled {
        cursor: initial;

        .symptom-item-text{
            color: #596f81;

        }

        :hover{
            background: #05aa9769;
        }

    }

   
`

const SugguestedSymptomItem = styled.li`
    height: 40px;
    padding-left: 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: var(--GreyColorOne);
    font-family: var(--Font1NotCondensed);
    font-weight: bold;

    .symptom-item-name{
        white-space: nowrap;
    
    }
    .add-button{
        background: #05aa9769;
        border: 0px solid transparent;
        padding-left: 10px;
        padding-top: 5px;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 5px;
        padding-right: 10px;
        font-size: 9px;
        color: #596f81;
        border-radius: 4px;
    }
`

const SelectedSymptomsWrapper = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 0px 20px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    font-family: var(--Font2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    .my-symptoms-text{
        height: 80px;
        font-size: 20px;
        display: flex;
        align-items: center;
        text-align: left;
        font-family: var(--Font1NotCondensed);
        font-weight: bold;
        color: var(--TextTitleColor2);

        width: 100%;
    }

    .selected-symptoms-list{
        height: calc(100% - 50px);
        width: 100%;
    }
`

const SugguestedSymptomList = styled.ul`
    width: 100%;
    position: absolute;
    top: 82px;
    background: white;
    margin-top: 0px;
    border: 1px solid grey;
    z-index: 200;
    overflow-y: scroll;
    max-height: 300px;
    border-radius: 0px 0px 2px 2px;

    opacity: ${props => props.visible === true? '1;' : '0;'};

`

export const NoSymptomsAddedWrapper = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    color: rgba(0, 0, 0, 0.4);
    font-family: var(--Font2);
`
function SelectSymptoms(props) {
    console.log(props, 'this is props inside of symptom cehcker')
    let [allSymptoms, setAllSymptoms] = useState([])
    let [selectedSymptoms, setSelectedSymptoms] = useState([])
    let [text, setText] = useState("")
    let [suggustedSymptoms, setSugguestedSymptoms] = useState([])
    let [foundAilment, setFoundAilment] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            let { data } = props;
            let { allMongodbHerbsSymptoms } = data;
            setAllSymptoms(allMongodbHerbsSymptoms.edges)
        }
        return () => {
            isSubscribed = false
        }
    }, [])

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && props.search.selected) {
            let parsedSymptoms = props.search.selected.split(',')
            if (parsedSymptoms) {
                setSelectedSymptoms(parsedSymptoms)
            }
        }
        return () => {
            isSubscribed = false
        }
    }, [props.search && props.search.select])


    const handleAddSymptom = (_id) => {
        let tempArray = new Array(...selectedSymptoms);
        console.log(tempArray, 'this is temp array')
        tempArray.push(_id);
        setSelectedSymptoms(tempArray)
    }

    const handleRemoveSymptom = (index) => {
        let tempArray = new Array(...selectedSymptoms);
        tempArray.splice(index, 1)
        setSelectedSymptoms(tempArray)
    }

    const handleSubmit = () => {
        navigate(`${SYMPTOM_CHECKER_AILMENT_RESULTS}?symptoms=${selectedSymptoms}`)
    }

    let renderDefaultSymptoms = allSymptoms.slice(0, 8).map((item, index) => {

        return (
            <SymptomStyledItem disabled={selectedSymptoms.includes(item.node.mongodb_id)} onClick={() => handleAddSymptom(item.node.mongodb_id)} key={index}>
                <div className="symptom-item-text">

                    {item.node.name}
                </div>

            </SymptomStyledItem>
        )
    })

    let renderSelectedSymptoms = selectedSymptoms ? selectedSymptoms.map((id, index) => {
        let item = allSymptoms.find((desiredItem) => desiredItem.node.mongodb_id === id);
        return (
            <SymptomStyledItem onClick={() => handleRemoveSymptom(index)} key={index}>
                <div className="symptom-item-text">

                    {item.node.name}
                    <IconButton className={classes.iconButton} >
                        <ClearIcon className={classes.clearIcon} size="inherit" />
                    </IconButton>

                </div>

            </SymptomStyledItem>
        )
    }) : ""


    const handleTextFilter = (value) => {
        setText(value)

        let symptomsToReturn = []

        if (value.length >= 2) {


            allSymptoms.forEach((item) => {
                console.log(item)
                if (item.node.name.toLowerCase().includes(value.toLowerCase())) {
                    symptomsToReturn.push(item.node)
                }

            })
        }



        setSugguestedSymptoms(symptomsToReturn)
    }

    const handleSelectSugguestion = (item) => {
        handleAddSymptom(item.mongodb_id)
        setText("")
        setSugguestedSymptoms([])
    }



    const MySymptoms = <SelectedSymptomsWrapper>
        <div className="my-symptoms-text">
            My Symptoms
        </div>
        <div className="selected-symptoms-list">
            {renderSelectedSymptoms}

        </div>
    </SelectedSymptomsWrapper>


    const NoSymptomsAdded = <NoSymptomsAddedWrapper>
        <div className="message">
            No Symptoms Added
        </div>
    </NoSymptomsAddedWrapper>



    const renderSugguestedSymptoms = suggustedSymptoms ? suggustedSymptoms.map((item, index) => {
        if (!selectedSymptoms.includes(item.mongodb_id)) {
            return (
                <SugguestedSymptomItem onClick={() => handleSelectSugguestion(item)} key={index}>
                    {item.name}
                    <button className="add-button">Add</button>
                </SugguestedSymptomItem>)
        }

    }) : ""

    

    return (
        <SymptomCheckerStyled>
            <div className="walk-through-wrapper">

                <PageHeaderTypeThree fontFamily={"var(--Font1NotCondensed)"} marginBottom={5}>Symptom Checker</PageHeaderTypeThree>
                <PageSubHeaderTypeThree marginTop={0}>Identify possible conditions and treatment related to your symptoms.</PageSubHeaderTypeThree>
                <WalkThroughContainer>



                    <div className="symptom-checker-columns">
                        <div className="symptom-column-item">
                            <div className="input-column">
                                <label className="input-column-label">Add more symptoms</label>
                                <input placeholder="Type your symptom here" value={text} onChange={(e) => handleTextFilter(e.target.value)}></input>

                                <SugguestedSymptomList visible={suggustedSymptoms.length > 0 || text? true: false}>
                                    {suggustedSymptoms.length === 0?
                                <SugguestedSymptomItem >
                                    No Result
                                </SugguestedSymptomItem>: ""}
                                    {renderSugguestedSymptoms}
                                </SugguestedSymptomList>
                            </div>

                            <div className="default-symptom-wrapper">


                                <div className="wrapper-label">Or choose from these common symptoms</div>
                                <div className="defaultSymptomTags">
                                    {renderDefaultSymptoms}
                                </div>
                            </div>
                        </div>


                        <div className="selected-symptoms">
                            {selectedSymptoms.length ? MySymptoms : NoSymptomsAdded}
                        </div>
                    </div>

                    <footer className="symptom-checker-footer">
                        <div></div>
                        <Button disabled={selectedSymptoms.length < 1} disableElevation color="secondary"  variant="contained"  onClick={() => handleSubmit()}>
                            Next
                        </Button>

                    </footer>
                </WalkThroughContainer>
            </div>
            <div className={"symptom-checker-message-columns"}>
                <PageParagraphTypeTwo>
                This tool does not provide medical advice It is intended for informational purposes only. It is not a substitute for professional medical advice, diagnosis or treatment. Never ignore professional medical advice in seeking treatment because of something you have read on the FindAnHerb Site. If you think you may have a medical emergency, immediately call your doctor or dial 911.
                </PageParagraphTypeTwo>
            </div>

        </SymptomCheckerStyled>
    )
}

export default withLocation(SelectSymptoms)

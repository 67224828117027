import React, {useState, forwardRef} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import RemedyItemTypeTwo from '../../common/RemedyItemTypeTwo';
import { RemedyGridListWrapper, RemedyGridList, AilmentSectionItem } from '../../Ailment';
import { PageParagraphTypeOne, PageH2TypeOne, UnorderedListTypeOne, DividerLineOne, ShowingLimitOutOfCount } from '../../Layout/GlobalStyle';
import SymptomItem from '../../Ailment/SymptomItem';
import UseTopicItem from '../../UseTopicItem';
import DisclaimerItem from '../../common/DisclaimerItem';
import LoadingContainer from '../../common/LoadingContainer';
import { Button } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: '#fff',
      boxShadow: 'none !important',
      override: {
              
             
      }
      

    },
    tabs: {
        fontFamily: "var(--Font1NotCondensed)",
        fontWeight: 'bold'
    },
    tabStyles: {
        background: 'inherit'
    },
    loadMoreRemediesButton: {
        fontFamily: "var(--Font1NotCondensed)",
        fontWeight: "bold",
        marginTop: 20,
        textTransform: 'initial'
    }
  }));

const Container = styled.div`
    position: relative;
    display: flex;
    position: relative;
    flex-direction: column;
    ${props => props.visible? "opacity: 1;": "opacity: 0;"}
    .ailment-header-wrapper{

    }



    .ailment-result-title{
        text-align: center;
        font-size: 26px;
        font-family: var(--Font1NotCondensed);
        font-weight: bold;
        margin-bottom: 15px;
        color: var(--TextTitleColor2);
    }

    .ailment-information-section{
        padding-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .condition-details{
        padding-top: 30px;
        display: flex;
        flex-direction: column;
    }

    .ailment-body{
        /* padding-top: 40px; */
    }

    .condition-details{

    }

    .remedies-tab{
        display: grid;
    }

.mySpan{
    height: 1px;
    width: 1px;
    top: -100px;
    position: absolute;

}

`

function AilmentResult({remedies, loadingRemedies, remediesCount, remediesLimit,  fetchingMoreRemedies, setRemediesLimit, forwardRef, ailment: {_id, name, fact, symptoms, howCommon, description,questionsToAskYourDr, diagnosedBy, madeWorseBy, didYouKnow, cautionText, selfCare, whatToExpect}}) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

      const renderRemedies = remedies.map((item, index) => {
       
        return <RemedyItemTypeTwo showAilments={false} itemIndex={index} key={index} item={item} />
    })



    let renderSymptoms =  symptoms? symptoms.map((id, index) => {
        if (id) {
            return (<SymptomItem key={index} id={id} />)
        }
    }): ""
    
    return (
        <Container  visible={name? true: false} >
        <div className="mySpan" ref={forwardRef} >

        </div>
    <div className="ailment-information">
        <header className="ailment-header-wrapper">

            <div className="ailment-result-title">{name}</div>
        </header>
        <div className="ailment-body">
    <AppBar elevation={0}  className={classes.tabStyles}  position="static">
    <Tabs  
        indicatorColor="primary"
        textColor="primary"
    
        variant="fullWidth"
        value={tabValue} onChange={handleTabChange} aria-label="ailment tabs"
    >
        <Tab         className={classes.tabs}
  label="Condition Details" {...a11yProps(0)} />
        <Tab className={classes.tabs} label="Remedies" {...a11yProps(1)} />
    </Tabs>
</AppBar>
      <TabPanel  value={tabValue} index={0}>
        <div className="condition-details">
        <PageH2TypeOne>Overview</PageH2TypeOne>
        <DividerLineOne />

            <PageParagraphTypeOne marginTop={"0px"}>
                {description}
            </PageParagraphTypeOne>
            <section className="ailment-information-section">
            {symptoms && symptoms.length ?

            <AilmentSectionItem>
                <PageH2TypeOne>Symptoms</PageH2TypeOne>
                <DividerLineOne />
                <UnorderedListTypeOne>
                    {renderSymptoms}
                </UnorderedListTypeOne>

            </AilmentSectionItem>
            : ""}

{whatToExpect && whatToExpect.trim().length? 
    <AilmentSectionItem>
        <PageH2TypeOne>
            What to expect
        </PageH2TypeOne>
        <DividerLineOne />
        <PageParagraphTypeOne marginTop={'0px'}>
            {whatToExpect}
        </PageParagraphTypeOne>
    </AilmentSectionItem>

: ""}

{madeWorseBy && madeWorseBy.trim().length ?
                        <AilmentSectionItem>
                            <PageH2TypeOne>
                                Made Worse By
                        </PageH2TypeOne>
                            <DividerLineOne />

                            <PageParagraphTypeOne marginTop={'0px'}>
                                {madeWorseBy}
                            </PageParagraphTypeOne>
                        </AilmentSectionItem>
                        : ""}

{questionsToAskYourDr && questionsToAskYourDr.length ?
                        <AilmentSectionItem>
                            <PageH2TypeOne>
                                Questions to ask your Doctor
                        </PageH2TypeOne>
                            <DividerLineOne />

                            <PageParagraphTypeOne marginTop={'0px'}>
                                {questionsToAskYourDr.map(((text, index) => {
                                    return (<UseTopicItem key={index} text={text} />)
                                }))}
                            </PageParagraphTypeOne>
                        </AilmentSectionItem>
                        : ""}


{howCommon && howCommon.trim().length ?
                        <AilmentSectionItem>
                            <PageH2TypeOne>
                                How Common
                        </PageH2TypeOne>
                            <DividerLineOne />

                            <PageParagraphTypeOne marginTop={'0px'}>
                                {howCommon}
                            </PageParagraphTypeOne>
                        </AilmentSectionItem>
                        : ""}



{diagnosedBy && diagnosedBy.trim().length ?
                        <AilmentSectionItem>
                            <PageH2TypeOne>
                                Diagnosed By
                        </PageH2TypeOne>
                            <DividerLineOne />

                            <PageParagraphTypeOne marginTop={'0px'}>
                                {diagnosedBy}
                            </PageParagraphTypeOne>
                        </AilmentSectionItem>
                        : ""}

{fact && fact.trim().length ?
                        <AilmentSectionItem>
                            <PageH2TypeOne>
                                Fact
                            </PageH2TypeOne>
                            <DividerLineOne />

                            <PageParagraphTypeOne marginTop={'0px'}>
                                {fact}
                            </PageParagraphTypeOne>
                        </AilmentSectionItem>
                        : ""}

<div className="disclaimer-list">
                        {cautionText && cautionText.trim().length ?
                            <DisclaimerItem title={"Caution"} text={cautionText} />

                            : ""}
                    </div>

            </section>

        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      {loadingRemedies? <LoadingContainer /> : 
      <>
        <RemedyGridList>
           {renderRemedies}

        </RemedyGridList>
        {!loadingRemedies && remediesCount > remediesLimit ? <Button disabled={fetchingMoreRemedies} disableElevation color={"secondary"} variant={"contained"} className={classes.loadMoreRemediesButton} onClick={() => setRemediesLimit((prevLimit) => (prevLimit + 2))}>{fetchingMoreRemedies? "Loading more remedies" : "See more remedies"} </Button>: ""}

    </>
    }

      </TabPanel>
        </div>

    </div> 
    </Container>

    )
}

AilmentResult.propTypes = {
    remedies: PropTypes.array,
    loadingRemedies: PropTypes.bool.isRequired,
    fetchingMoreRemedies: PropTypes.bool.isRequired,
    remediesLimit: PropTypes.number.isRequired,
    remediesCount: PropTypes.number.isRequired,
    setRemediesLimit: PropTypes.func.isRequired,
    ailment: {
        fact: PropTypes.string,
        howCommon: PropTypes.string,
        description: PropTypes.string,
        diagnosedBy: PropTypes.string,
        madeWorseBy: PropTypes.string,
        didYouKnow: PropTypes.string,
        cautionText: PropTypes.string,
        symptoms: PropTypes.array,
        name: PropTypes.string,
        selfCare: PropTypes.string,
        whatToExpect: PropTypes.string,
        questionsToAskYourDr: PropTypes.array
    }
}

export default forwardRef((props, ref) => { return <AilmentResult {...props} forwardRef={ref} /> })